import { withAuthenticationRequired } from '@auth0/auth0-react'
import Layout from 'manage-tritag/components/layout'
import EnhancedTable from 'manage-tritag/components/table/enhanced-table'
import {
  useLazyGetUsersQuery,
  User,
} from 'manage-tritag/services/api/endpoints/users'
import { Box } from '@mui/material'
import Spinner from 'manage-tritag/components/loading/spinner'
import EnhancedTableFilter from 'manage-tritag/components/table/enhanced-table/enhanced-table-filter'
import { QueryParams } from 'manage-tritag/services/api/endpoints/sub-masters'
import { useEffect, useState } from 'react'

interface HeadCell {
  disablePadding: boolean
  id: keyof User
  label: string
  numeric: boolean
}

const UserManagementPage = () => {
  const [params, setParams] = useState<QueryParams>({
    offset: 0,
    limit: 100000,
    search: '',
  })
  const [trigger, result] = useLazyGetUsersQuery()
  const { isLoading, data: users } = result

  useEffect(() => {
    trigger(params)
  }, [params, trigger])

  const headCells: HeadCell[] = [
    {
      id: 'user_id',
      numeric: false,
      disablePadding: false,
      label: 'ID',
    },
    {
      id: 'given_name',
      numeric: false,
      disablePadding: false,
      label: 'First Name',
    },
    {
      id: 'family_name',
      numeric: false,
      disablePadding: false,
      label: 'Last Name',
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: 'Email',
    },
  ]
  const bodyCells = ['user_id', 'given_name', 'family_name', 'email']
  return (
    <Layout>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 145px);',
          }}
        >
          <Spinner size={40} color="#008174" />
        </Box>
      ) : (
        users &&
        Array.isArray(users) && (
          <>
            <EnhancedTableFilter
              params={params}
              setParams={setParams}
              notCreate
            />
            <EnhancedTable
              tableTitle="Users"
              uniqueIdentifier="_id"
              rows={users.map((user, index) => ({
                ...user,
                _id: user.user_id,
                user_id: index + 1,
                family_name: user.family_name ? user.family_name : user.name,
              }))}
              headCells={headCells}
              bodyCells={bodyCells}
            />
          </>
        )
      )}
    </Layout>
  )
}

export default withAuthenticationRequired(UserManagementPage)
